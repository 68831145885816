import { AxiosResponse } from 'axios';
import SuccessRs from '@domain/rs/common/SuccessRs';
import { axios } from '@repository/RequestClient';
import { AccFeedbackSaveRq } from '@domain/rq/acca/feedback/AccFeedbackSaveRq';
import AccDeleteRq from '@domain/rq/acca/AccDeleteRq';
import { AccMaxApplyChanceRs } from '@domain/rs/acca/test/AccMaxApplyChanceRs';
import { AccResultRecordRs } from '@domain/rs/acca/test/AccResultRecordRs';
import { AccProgressCompleteRs } from '@domain/rs/acca/test/AccProgressCompleteRs';
import { AccFeedbackExistsRs } from '@domain/rs/acca/v1/test/feedback/AccFeedbackExistsRs';
import { AccApplyRs } from '@domain/rs/match/profile/acc/ApplyRs';
import { AccProvidePastCodeRs } from '@domain/rs/match/profile/acc/AccResultRs';
import ApplyHistoryListRs from '@domain/rs/acca/test/ApplyHistoryListRs';
import { ApplyCopyTaskListRs } from '@domain/rs/acca/save/ApplyCopyTaskListRs';
import AccResultTokenCreateRs from '@domain/rs/acca/results/AccResultTokenCreateRs';

/** 잡다에서 요청하는 역량검사 결과표 **/
export default interface AccRepo {
  // 이전 응시 결과
  fetchAccResults(): Promise<AxiosResponse<AccResultRecordRs>>;

  // 응시 분석 완료 존재 여부
  fetchAccResultsAnalysisComplete(): Promise<AxiosResponse<boolean>>;

  // 이전 응시완료 결과 목록
  fetchAccResultsProgressComplete(): Promise<AxiosResponse<AccProgressCompleteRs>>;

  // 최대 응시 회수 조회
  fetchAccMaxApplyChance(): Promise<AxiosResponse<AccMaxApplyChanceRs>>;

  // 피드백 저장
  fetchAccaFeedback(rq: AccFeedbackSaveRq): Promise<AxiosResponse<SuccessRs>>;

  // 피드백 작성 완료 여부
  fetchAccaFeedbackExists(sn: number): Promise<AxiosResponse<AccFeedbackExistsRs>>;

  // AI 역량검사 응시 코드 생성
  fetchAccApplyCode(): Promise<AxiosResponse<AccApplyRs>>;

  // 역량검사 응시결과 ACC 보내기
  fetchPostAccApplyCode(): Promise<AxiosResponse<AccProvidePastCodeRs>>;

  //응시 이력 삭제
  fetchDeleteApplyHistory(rq: AccDeleteRq): Promise<AxiosResponse>;

  //응시명 수정
  fetchUpdateApplyName(sn: number, name: string): Promise<AxiosResponse>;

  //응시 이력 조회
  fetchApplyHistoryList(): Promise<AxiosResponse<ApplyHistoryListRs>>;

  //응시 복사하기
  fetchCopyApply(key: string, name: string): Promise<AxiosResponse<ApplyCopyTaskListRs>>;

  //응시 복사 가능 여부 조회
  fetchAvailableCopyApply(key: string): Promise<AxiosResponse>;

  // 응시 분석 결과표 토큰 발급
  fetchAccResultsToken(code: string): Promise<AxiosResponse<AccResultTokenCreateRs>>;

  // 응시 결과표 토큰 예시
  fetchAccResultsTokenExample(): Promise<AxiosResponse<AccResultTokenCreateRs>>;

  // 매칭에 사용될 결과표 선택
  fetchAccResultsSelectMatching(applySn: number): Promise<AxiosResponse>;
}

export class RemoteAccRepo implements AccRepo {
  fetchAccResults() {
    return axios.get<AccResultRecordRs>('/v2/acc/results');
  }

  fetchAccResultsAnalysisComplete() {
    return axios.get<boolean>('/acc/results/analysis-complete');
  }

  fetchAccResultsProgressComplete() {
    return axios.get<AccProgressCompleteRs>('/acc/results/progress-complete');
  }

  fetchAccMaxApplyChance() {
    return axios.get<AccMaxApplyChanceRs>('/acc/max-apply-chance');
  }

  fetchAccaFeedback(rq: AccFeedbackSaveRq) {
    return axios.post<SuccessRs>('/acc/results/feedback', rq);
  }

  fetchAccaFeedbackExists(sn: number) {
    return axios.get<AccFeedbackExistsRs>('/acc/results/feedback/exists', { params: { sn } });
  }

  fetchAccApplyCode() {
    return axios.put<AccApplyRs>('/acc/apply');
  }

  fetchPostAccApplyCode() {
    return axios.post<AccProvidePastCodeRs>('/acc/provide-past-code');
  }

  fetchDeleteApplyHistory(rq: AccDeleteRq) {
    return axios.delete('/v3/acc', {
      data: rq,
    });
  }

  fetchUpdateApplyName(sn: number, name: string) {
    return axios.put('/v1/acc', {
      sn,
      name,
    });
  }

  fetchApplyHistoryList() {
    return axios.get<ApplyHistoryListRs>('/v1/acc/apply');
  }

  fetchCopyApply(key: string, name: string) {
    return axios.put('/v1/acc/apply/copy', {
      key,
      name,
    });
  }

  fetchAvailableCopyApply(key: string) {
    return axios.get<ApplyCopyTaskListRs>(`/v1/acc/apply/copy/${key}`);
  }

  fetchAccResultsToken(code: string): Promise<AxiosResponse<AccResultTokenCreateRs>> {
    return axios.get<AccResultTokenCreateRs>('/acc/results/token', { params: { code } });
  }

  fetchAccResultsTokenExample() {
    return axios.get<AccResultTokenCreateRs>('/acc/results/token/example');
  }

  fetchAccResultsSelectMatching(applySn: number): Promise<AxiosResponse> {
    return axios.put('/acc/results/select-matching', { applySn });
  }
}
