import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Portal from '@common/portal';
import CustomImage from '@components/common/image';
import usePopContents from '@repository/popup/usePopupContents';
import { PopupDto } from '@domain/rs/popup/PopupRs';
import { MILLISECONDS_PER_DAY } from '@domain/constant/Time';
import LocalStorageUtil from '@utils/storage/localStorage';
import DateUtil from '@utils/date/util';
import TextFormat from '@utils/text/format';
import { bodyScrollHidden } from '@utils/common';
import classnames from 'classnames/bind';
import styles from './layerModal.module.scss';
const cx = classnames.bind(styles);

interface LayerModalItemProps {
  popups: PopupDto[];
}

const LayerModalItem = ({ popups }: LayerModalItemProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [modalData, setModalData] = useState<PopupDto[]>([]);

  const handleStop = () => {
    const modalItems = LocalStorageUtil.getItem('modal');
    const snsObject = popups.reduce<Record<string, number>>((acc, cur) => {
      acc[cur.sn] = cur.sn;
      return acc;
    }, {});
    const value = modalItems ? { ...JSON.parse(modalItems), ...snsObject } : snsObject;
    LocalStorageUtil.setItem('modal', JSON.stringify(value), { expires: new Date(Date.now() + MILLISECONDS_PER_DAY) });
    setIsVisible(false);
  };

  useEffect(() => {
    const popupItems = LocalStorageUtil.getItem('modal');

    const filteredPopups = popups.filter((item) => {
      const { sn, postStartDateTime, postEndDateTime, viewType } = item;
      if (viewType !== 'MODAL') return false;
      if (!popupItems || !JSON.parse(popupItems)[sn]) {
        // 그 안에서도 시간이 지났는지 안 지났는지 확인하기
        if (DateUtil.isPastDate(new Date(postStartDateTime))) return true;
        if (DateUtil.isPastDate(new Date(postStartDateTime)) && DateUtil.isPastDate(new Date(postEndDateTime)))
          return false;
      }
    });

    if (filteredPopups.length > 0) {
      setModalData(filteredPopups);
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [popups]);

  useEffect(() => {
    if (isVisible) bodyScrollHidden(true);

    return () => {
      bodyScrollHidden(false);
    };
  }, [isVisible]);

  if (!isVisible) return null;

  const data = modalData[0];
  const isNewWindow = data.linkType === 'NEW_WINDOW';

  return (
    <>
      <div className={cx('dimmed')} />
      <div className={cx('layerModal')}>
        <div className={cx('layerModalArea')}>
          <Link
            className={cx('popupImg')}
            onClick={() => setIsVisible(false)}
            href={TextFormat.formatProtocol(data.link)} // href에 바로 링크 적용
            target={isNewWindow ? '_blank' : '_self'} // 새 창에서 열기 조건적 적용
            rel="noreferrer"
          >
            <CustomImage src={data.pcAttachFileUrl} width={400} height={400} alt="modal image" pointer />
          </Link>
          <div className={cx('buttonArea')}>
            <a onClick={handleStop} className={cx('button')} role="button">
              오늘은 그만 보기
            </a>
            <a onClick={() => setIsVisible(false)} className={cx('button')} role="button">
              닫기
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const LayerModal = () => {
  const { asPath, isReady } = useRouter();
  //pathname 사용시 [sn] 이 포함된 URL에 대해서는 /[SN]/로 리턴되는 이슈로  asPath 사용
  const { data } = usePopContents(asPath, isReady);

  if (!data) return null;

  const modalData = data.popups.filter((item) => item.viewType === 'MODAL');
  return (
    <Portal selector="#popup">
      <LayerModalItem popups={modalData} />
    </Portal>
  );
};

export default LayerModal;
