import { ProgressType } from '@domain/constant/acca/ProgressType';
import AccApplyRecentRs, { AccApplyItemDto } from '@domain/rs/acca/test/AccApplyRecentRs';
import DateFormat from '@utils/date/format';

export default class AccApplyRecentVO {
  applies: AccApplyItemVO[];
  accUrl: string;

  constructor(rs: AccApplyRecentRs) {
    this.applies = rs.applies.map((apply) => new AccApplyItemVO(apply));
    this.accUrl = rs.accUrl;
  }
}

export class AccApplyItemVO {
  sn: number;
  code: string;
  lastModifiedDateTime: string;
  title: string;
  isEnd: boolean;
  percentage: string;
  percentageText: string;
  thumbnailUrl: string;
  completed: number;
  total: number;
  visibleYn: boolean;
  progressType: ProgressType;

  constructor(dto: AccApplyItemDto) {
    this.sn = dto.sn;
    this.code = dto.code;
    this.lastModifiedDateTime = DateFormat.format(new Date(dto.lastModifiedDateTime), 'yyyy.MM.dd HH:mm');
    this.title = dto.title;
    this.isEnd = dto.completed === dto.total;
    this.percentage = `${Math.ceil((dto.completed / dto.total) * 100)}%`;
    this.percentageText = this.isEnd ? '응시완료' : `${this.percentage} 진행`;
    this.thumbnailUrl = dto.thumbnailUrl;
    this.completed = dto.completed;
    this.total = dto.total;
    this.visibleYn = dto.visibleYn;
    this.progressType = dto.progressType;
  }
}
