import { AxiosResponse } from 'axios';
import SuccessRs from '@domain/rs/common/SuccessRs';
import { axios } from '@repository/RequestClient';
import { MatchApplicationInterviewRq } from '@domain/rq/match/application/MatchApplicationRq';
import AccountLinkRq from '@domain/rq/accountLink/AccountLinkRq';
import MatchingApplicationApplyRq from '@domain/rq/match/MatchingApplicationApplyRq';
import MatchingRejectRq from '@domain/rq/match/MatchingRejectRq';
import MatchingAdjustRq from '@domain/rq/match/application/MatchingAdjustRq';
import MatchApplicationCertificationRq from '@domain/rq/match/MatchApplicationCertificationRq';
import { CheckWritingResumeRs } from '@domain/rs/accountLink/CheckWritingResumeRs';
import ResumeAgreeUrlRs from '@domain/rs/match/ResumeAgreeUrlRs';
import { MatchingRequirementRs } from '@domain/rs/match/MatchingRequirementRs';
import ResumeAdditionInfoRs from '@domain/rs/match/ResumeAdditionInfoRs';
import MatchApplicationCertificationRs from '@domain/rs/match/MatchApplicationCertificationRs';
import { PositionAccStatusRs } from '@domain/rs/match/PositionAccStatusRs';

export default interface MatchApplicationRepo {
  // 추가정보 임시 저장 (프로필)
  saveAdditionalInfo(rq: MatchingApplicationApplyRq): Promise<AxiosResponse<boolean>>;

  // 입사 지원 (프로필)
  applyPosition(rq: MatchingApplicationApplyRq): Promise<AxiosResponse<boolean>>;

  // 입사 지원 취소
  fetchCancelApplication(positionSn: number): Promise<AxiosResponse<boolean>>;

  // 매칭 포지션 입사취소
  fetchCancelMatchPosition(positionSn: number): Promise<AxiosResponse<boolean>>;

  // 매칭 포지션 면접 제안 수락
  acceptInterview(rq: MatchApplicationInterviewRq): Promise<AxiosResponse<boolean>>;

  // 매칭 포지션 면접 제안 거절
  rejectInterview(rq: MatchingRejectRq): Promise<AxiosResponse<boolean>>;

  // 추가 서류 제출 파일 업로드
  uploadApplicationFiles(positionSn: number, optionSn: number, files: File[]): Promise<AxiosResponse<boolean>>;

  // 추가 서류 제출 파일 다운로드
  downloadApplicationFiles(positionSn: number, fileUid: string): Promise<AxiosResponse<ArrayBuffer>>;

  // 작성중 지원서 있는지 체크
  fetchCheckWritingResume(rq: AccountLinkRq): Promise<AxiosResponse<CheckWritingResumeRs>>;

  // 작성 중 지원서 삭제
  fetchRemoveResume(positionSn: number): Promise<AxiosResponse<SuccessRs>>;

  // 지원서 취소 전 동의 여부 체크
  fetchCheckConsent(positionSn: number): Promise<AxiosResponse<boolean>>;

  // 지원서 잡다에 저장 동의
  fetchProcessingConsent(positionSn: number): Promise<AxiosResponse<boolean>>;

  // 포지션 지원서 동의 url
  fetchAgreeUrl(positionSn: number): Promise<AxiosResponse<ResumeAgreeUrlRs>>;

  // 포지션 제출한 지원서 번호 조회
  fetchResumeNumber(positionSn: number): Promise<AxiosResponse<number>>;

  // 응시 결과 제출
  fetchSubmitApply(positionSn: number, optionSn: number, applySn: number): Promise<AxiosResponse<boolean>>;

  // 입사 지원 조건 검증
  fetchApplyVerification(positionSn: number): Promise<AxiosResponse<boolean>>;

  // 포지션 필수값 검증
  fetchRequiredItems(positionSn: number): Promise<AxiosResponse<MatchingRequirementRs>>;

  // 포지션 추가 정보 항목 조회
  fetchAdditionalItems(positionSn: number): Promise<AxiosResponse<ResumeAdditionInfoRs>>;

  //면접 일정 조율
  fetchApplicationInterviewAdjusted(rq: MatchingAdjustRq): Promise<AxiosResponse<boolean>>;

  //취업활동증명서 리스트
  fetchApplicationCertification(
    rq: MatchApplicationCertificationRq,
  ): Promise<AxiosResponse<MatchApplicationCertificationRs>>;

  //포지션 역검 필수 여부 및 구직자의 역검 전형상태 확인
  fetchApplicationPositionAccStatus(positionSn: number): Promise<AxiosResponse<PositionAccStatusRs>>;
}

export class RemoteMatchApplicationRepo implements MatchApplicationRepo {
  saveAdditionalInfo(rq: MatchingApplicationApplyRq) {
    const { positionSn, ...data } = rq;
    return axios.put<boolean>(`/match/application/${positionSn}/addition-info`, data);
  }

  applyPosition(rq: MatchingApplicationApplyRq) {
    const { positionSn, ...data } = rq;
    return axios.post<boolean>(`/match/application/${positionSn}/apply`, data);
  }

  fetchCancelApplication(positionSn: number): Promise<AxiosResponse<boolean>> {
    return axios.delete(`/match/application/${positionSn}/apply`);
  }

  fetchCancelMatchPosition(positionSn: number) {
    return axios.delete<boolean>(`/match/application/${positionSn}/apply`);
  }

  acceptInterview({ positionSn, optionSn }: MatchApplicationInterviewRq) {
    return axios.put<boolean>(`/match/application/${positionSn}/interview-accept`, null, { params: { optionSn } });
  }

  rejectInterview(rq: MatchingRejectRq) {
    return axios.put<boolean>(`/v2/match/application/${rq.positionSn}/interview-denied`, rq);
  }

  uploadApplicationFiles(positionSn: number, optionSn: number, files: File[]) {
    const formData: FormData = new FormData();
    files.forEach((file) => formData.append('files', file));
    return axios.post<boolean>(`/match/application/${positionSn}/files`, formData, { params: { optionSn } });
  }

  downloadApplicationFiles(positionSn: number, fileUid: string) {
    return axios.get<ArrayBuffer>(`/match/application/${positionSn}/files`, {
      params: { fileUid },
      responseType: 'arraybuffer',
    });
  }

  fetchCheckWritingResume(rq: AccountLinkRq): Promise<AxiosResponse<CheckWritingResumeRs>> {
    return axios.get<CheckWritingResumeRs>('/match/application/resume/exists', {
      params: rq,
    });
  }

  fetchRemoveResume(positionSn: number) {
    return axios.delete<SuccessRs>(`/match/application/${positionSn}/resume`);
  }

  fetchCheckConsent(positionSn: number) {
    return axios.get<boolean>(`/match/application/${positionSn}/resume/agree`);
  }

  fetchProcessingConsent(positionSn: number) {
    return axios.post<boolean>(`/match/application/${positionSn}/resume/agree`);
  }

  fetchAgreeUrl(positionSn: number): Promise<AxiosResponse<ResumeAgreeUrlRs>> {
    return axios.get<ResumeAgreeUrlRs>(`/match/application/${positionSn}/resume/agree/url`);
  }

  fetchResumeNumber(positionSn: number): Promise<AxiosResponse<number>> {
    return axios.get<number>(`/match/application/${positionSn}/resume`);
  }

  fetchSubmitApply(positionSn: number, optionSn: number, applySn: number): Promise<AxiosResponse<boolean>> {
    return axios.put<boolean>(`/match/application/${positionSn}/submit-apply`, null, { params: { optionSn, applySn } });
  }

  fetchApplyVerification(positionSn: number): Promise<AxiosResponse<boolean>> {
    return axios.post<boolean>(`/match/application/v1/${positionSn}/apply/verification`);
  }

  fetchRequiredItems(positionSn: number): Promise<AxiosResponse<MatchingRequirementRs>> {
    return axios.get<MatchingRequirementRs>(`/match/application/${positionSn}/requirement/verification`);
  }

  fetchAdditionalItems(positionSn: number): Promise<AxiosResponse<ResumeAdditionInfoRs>> {
    return axios.get<ResumeAdditionInfoRs>(`/match/application/${positionSn}/addition-info`);
  }

  fetchApplicationInterviewAdjusted(rq: MatchingAdjustRq): Promise<AxiosResponse<boolean>> {
    return axios.put<boolean>(`/match/application/${rq.positionSn}/interview-adjusted`, rq);
  }

  fetchApplicationCertification(
    rq: MatchApplicationCertificationRq,
  ): Promise<AxiosResponse<MatchApplicationCertificationRs>> {
    return axios.post(`/match/home/application/certification`, rq);
  }

  fetchApplicationPositionAccStatus(positionSn: number): Promise<AxiosResponse<PositionAccStatusRs>> {
    return axios.get(`/match/application/${positionSn}/position-acc-status`);
  }
}
