import { AxiosError } from 'axios';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';
import { RemoteAccRepo } from '@repository/acc/AccRepo';
import AccaCopyApplyRq from '@domain/rq/acca/save/AccaCopyApplyRq';
import ApplyUpdateNameRq from '@domain/rq/acca/save/ApplyUpdateNameRq';
import AccDeleteRq from '@domain/rq/acca/AccDeleteRq';
import ApplyHistoryListVO from '@domain/vo/acca/test/ApplyHistoryListVO';
import { ApplyCopyTaskListRs } from '@domain/rs/acca/save/ApplyCopyTaskListRs';

const remoteAccRepo = new RemoteAccRepo();
// const remoteAccRepo = new AccMock();

const keys = ['acca'];
const availableCopyApplyKeys = [...keys, 'availableCopyApply'];
const applyHistoryKeys = [...keys, 'applyHistory', 'list'];

const fetchAccMaxApplyChance = async () => {
  const { data } = await remoteAccRepo.fetchAccMaxApplyChance();
  return data.maxApplyChance;
};

const fetchCopyApply = async (rq: AccaCopyApplyRq) => {
  const { data } = await remoteAccRepo.fetchCopyApply(rq.key, rq.name);
  return data;
};

const fetchAvailableCopyApply = async (key: string) => {
  const { data } = await remoteAccRepo.fetchAvailableCopyApply(key);
  return data;
};

const fetchApplyHistoryList = async () => {
  const { data } = await remoteAccRepo.fetchApplyHistoryList();
  return new ApplyHistoryListVO(data);
};

const fetchUpdateApplyName = async (rq: ApplyUpdateNameRq) => {
  const { data } = await remoteAccRepo.fetchUpdateApplyName(rq.sn, rq.name);
  return data;
};

const fetchDeleteApplyHistory = async (rq: AccDeleteRq) => {
  const { data } = await remoteAccRepo.fetchDeleteApplyHistory(rq);
  return data;
};

const fetchAccResultsToken = async (code: string) => {
  const { data } = await remoteAccRepo.fetchAccResultsToken(code);
  return data;
};

const fetchAccResultsTokenExample = async () => {
  const { data } = await remoteAccRepo.fetchAccResultsTokenExample();
  return data;
};

const fetchAccResultsSelectMatching = async (applySn: number) => {
  const { data } = await remoteAccRepo.fetchAccResultsSelectMatching(applySn);
  return data;
};

const fetchAccaFeedback = async (favorites: string[], rating: number, sn: number) => {
  const { data } = await remoteAccRepo.fetchAccaFeedback({ favorites, rating, sn });
  return data;
};

const fetchAccaFeedbackExists = async (sn: number) => {
  const { data } = await remoteAccRepo.fetchAccaFeedbackExists(sn);
  return data;
};

const fetchAccResults = async () => {
  const { data } = await remoteAccRepo.fetchAccResults();
  return data;
};

function useCopyApply(onSuccess: (data: ApplyCopyTaskListRs) => void, onError: (err: AxiosError) => void) {
  return useMutation((rq: AccaCopyApplyRq) => fetchCopyApply(rq), {
    onSuccess,
    onError,
  });
}

function useAvailableCopyApply(enabled: boolean, key: string, onError: (err: AxiosError) => void) {
  return useQuery([...availableCopyApplyKeys, key], () => fetchAvailableCopyApply(key), {
    enabled,
    onError,
    retry: false,
  });
}

function useApplyHistoryList(enabled: boolean, options?: UseQueryOptions) {
  return useQuery(applyHistoryKeys, fetchApplyHistoryList, {
    enabled,
    ...(options as any),
  });
}

function useDeleteApplyHistory(onSuccess?: () => void, onError?: (err: AxiosError) => void) {
  return useMutation((rq: AccDeleteRq) => fetchDeleteApplyHistory(rq), {
    onSuccess,
    onError,
  });
}

function useUpdateApplyName(onSuccess?: () => void, onError?: (err: AxiosError) => void) {
  return useMutation((rq: ApplyUpdateNameRq) => fetchUpdateApplyName(rq), {
    onSuccess,
    onError,
  });
}

function useAccResultsToken(code: string, enabled: boolean) {
  return useQuery([...keys, 'results', code], () => fetchAccResultsToken(code), {
    enabled,
  });
}

function useAccResultsTokenExample() {
  return useQuery([...keys, 'results', 'example'], fetchAccResultsTokenExample);
}

function useAccMaxApplyChance() {
  return useQuery([...keys, 'maxCount'], fetchAccMaxApplyChance);
}

function useAccResultSelectMatching(onSuccess?: () => void, onError?: (err: AxiosError) => void) {
  return useMutation((applySn: number) => fetchAccResultsSelectMatching(applySn), {
    onSuccess,
    onError,
  });
}

function useAccaFeedback({ onSuccess, onError }: { onSuccess?: () => void; onError?: (err: AxiosError) => void } = {}) {
  return useMutation(
    ({ favorites, rating, sn }: { favorites: string[]; rating: number; sn: number }) =>
      fetchAccaFeedback(favorites, rating, sn),
    {
      onSuccess,
      onError,
    },
  );
}

const fetchPostAccApplyCode = async () => {
  const { data } = await remoteAccRepo.fetchPostAccApplyCode();
  return data;
};

const usePostAccApplyCode = (options?: UseMutationOptions) => {
  return useMutation(fetchPostAccApplyCode, options as any);
};

function useAccResults(options?: UseQueryOptions) {
  return useQuery([...keys, 'results'], fetchAccResults, options as any);
}

const fetchAccResultsAnalysisComplete = async () => {
  const { data } = await remoteAccRepo.fetchAccResultsAnalysisComplete();
  return data;
};

function useAccResultsAnalysisComplete(options?: UseQueryOptions) {
  return useQuery([...keys, 'results', 'analysis', 'complete'], fetchAccResultsAnalysisComplete, options as any);
}

export {
  keys as accKeys,
  availableCopyApplyKeys as accAvailableCopyApplyKeys,
  applyHistoryKeys as accApplyHistoryKeys,
  fetchAccaFeedbackExists,
  fetchAccResults,
  useCopyApply,
  useAvailableCopyApply,
  useApplyHistoryList,
  useDeleteApplyHistory,
  useUpdateApplyName,
  useAccResultsToken,
  useAccResultsTokenExample,
  useAccMaxApplyChance,
  useAccResultSelectMatching,
  useAccaFeedback,
  usePostAccApplyCode,
  useAccResults,
};
