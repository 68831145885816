import { StylesConfig } from 'react-select';
import Colors from '@domain/constant/colors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ReactSelectStyles: StylesConfig<Record<string, any>, true> = {
  container: (base: any, state: any) => ({
    // @ts-ignore
    borderColor: state.isFocused && `${Colors.C_JOBDA_BLACK} !important`,
    borderRadius: '4px',
    // @ts-ignore
    backgroundColor: state.isFocused ? `${Colors.C_WHITE} !important` : `${Colors.C_COOL_GRAY_30} !important`,
    opacity: state.isDisabled ? '0.5' : '1',
  }),
  control: () => ({
    position: 'relative',
    cursor: 'pointer',
  }),
  valueContainer: () => ({
    position: 'relative',
    height: 46,
    padding: '0 46px 0 16px',
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: `-0.01em`,

    ':after': {
      position: 'absolute',
      top: '50%',
      right: 16,
      width: 32,
      height: 32,
      backgroundImage: `url('/img/v2/components/common/reactSelect/iconLargeArrowBottomLight.png')`,
      backgroundSize: 'cover',
      transform: 'translateY(-50%)',
      content: `''`,
    },
  }),
  singleValue: () => ({
    position: 'absolute',
    top: '50%',
    right: 46,
    left: 16,
    color: Colors.C_JOBDA_BLACK,
    transform: 'translateY(-50%)',
  }),
  placeholder: () => ({
    position: 'absolute',
    top: 0,
    right: 46,
    bottom: 0,
    left: 16,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: `-0.01em`,
    color: Colors.C_COOL_GRAY_60,
  }),
  input: () => ({
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: `-0.01em`,
    color: Colors.C_JOBDA_BLACK,
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  menu: () => ({
    position: 'absolute',
    right: 0,
    bottom: -4,
    left: 0,
    border: `1px solid ${Colors.C_COOL_GRAY_50}`,
    borderRadius: 4,
    backgroundColor: Colors.C_WHITE,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)',
    transform: 'translateY(100%)',
    zIndex: 1,
  }),
  menuList: () => ({
    overflowY: 'auto',
    maxHeight: 182,
    padding: '4px 0',

    // Scrollbar 스타일 추가 (Chrome, Safari 등 Webkit 기반 브라우저)
    '::-webkit-scrollbar': {
      width: '4px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: Colors.C_COOL_GRAY_40,
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-button': {
      width: 0,
      height: 0,
    },
  }),

  option: (base, state) => ({
    padding: '12px 14px',
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: `-0.01em`,
    color: state.isSelected ? Colors.C_JOBDA_BLACK : Colors.C_COOL_GRAY_60,
    wordBreak: 'keep-all',
    cursor: 'pointer',

    ':hover': {
      backgroundColor: Colors.C_JOBDA_WHITE,
      color: Colors.C_COOL_GRAY_80,
    },
  }),
};

export const ReactSelectInputStyles: StylesConfig<Record<string, any>, true, any> = {
  container: (base: any, state: any) => ({
    // @ts-ignore
    borderColor: state.isFocused && `${Colors.C_JOBDA_BLACK} !important`,
    borderRadius: '4px',
    // @ts-ignore
    backgroundColor: state.isFocused ? `${Colors.C_WHITE} !important` : `${Colors.C_COOL_GRAY_30} !important`,
    opacity: state.isDisabled ? '0.5' : '1',
  }),
  control: () => ({
    position: 'relative',
    cursor: 'text',
  }),
  valueContainer: () => ({
    position: 'relative',
    height: 46,
    padding: '0 44px',
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: `-0.01em`,

    ':before': {
      position: 'absolute',
      top: '50%',
      left: 16,
      width: 24,
      height: 24,
      backgroundImage: `url('/img/v2/components/common/reactSelect/iconSearchLight.png')`,
      backgroundSize: 'cover',
      transform: 'translateY(-50%)',
      content: `''`,
    },
  }),
  singleValue: () => ({
    position: 'absolute',
    top: '50%',
    right: 16,
    left: 44,
    color: Colors.C_JOBDA_BLACK,
    transform: 'translateY(-50%)',
  }),
  placeholder: () => ({
    position: 'absolute',
    top: 0,
    right: 16,
    bottom: 0,
    left: 44,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: `-0.01em`,
    color: Colors.C_COOL_GRAY_60,
  }),
  input: () => ({
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: `-0.01em`,
    color: Colors.C_JOBDA_BLACK,
  }),
  indicatorsContainer: () => ({
    position: 'absolute',
    top: '50%',
    right: 16,
    transform: 'translateY(-50%)',
  }),
  loadingIndicator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  clearIndicator: () => ({
    width: 20,
    height: 20,
    cursor: 'pointer',

    ':before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: `url('/img/v2/components/common/reactSelect/iconClear.png')`,
      backgroundSize: 'cover',
      content: `''`,
    },

    svg: {
      display: 'none',
    },
  }),
  menu: () => ({
    position: 'absolute',
    right: 0,
    bottom: -4,
    left: 0,
    border: `1px solid ${Colors.C_COOL_GRAY_50}`,
    borderRadius: 4,
    backgroundColor: Colors.C_WHITE,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)',
    transform: 'translateY(100%)',
    zIndex: 1,
  }),
  menuList: () => ({
    overflowY: 'auto',
    maxHeight: 182,
    padding: '4px 0',
  }),
  option: (base, state) => ({
    padding: '12px 14px',
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: `-0.01em`,
    color: state.isSelected ? Colors.C_JOBDA_BLACK : Colors.C_COOL_GRAY_60,
    wordBreak: 'keep-all',
    cursor: 'pointer',

    ':hover': {
      backgroundColor: Colors.C_JOBDA_WHITE,
      color: Colors.C_COOL_GRAY_80,
    },
  }),
};
