import { MatchingRequirementRs } from '@domain/rs/match/MatchingRequirementRs';
import {
  BasicItemNameMap,
  BasicItemType,
  CareerItemNameMap,
  CareerItemType,
  EducationItemNameMap,
  EducationItemType,
  ExperienceItemNameMap,
  ExperienceItemType,
  FileItemNameMap,
  FileItemType,
  KnowledgeAndSkillItemNameMap,
  KnowledgeAndSkillItemType,
  PreferentialItemNameMap,
  PreferentialItemType,
  ProfileItemNameMap,
  ProfileItemType,
} from '@domain/constant/profileRequirementType';

type RequiredItem = {
  type: ProfileItemType;
  title: string;
  description: string;
};

export default class MatchingRequirementVO {
  requiredItems: RequiredItem[];
  isProfileComplete: boolean;

  constructor(rs: MatchingRequirementRs) {
    const items: RequiredItem[] = [];
    const requiredItems = rs.requiredItems || {};

    if (requiredItems.BASIC) {
      const description =
        requiredItems.BASIC.length > 0
          ? `${requiredItems.BASIC.map((basic) => BasicItemNameMap[basic as BasicItemType]).join(', ')}`
          : '';
      items.push({ type: 'BASIC', title: ProfileItemNameMap['BASIC'], description });
    }

    if (requiredItems.EDUCATION) {
      const filterEducation = requiredItems.EDUCATION.filter((edu) => edu !== 'EDUCATION');
      const description =
        filterEducation.length > 0
          ? `${filterEducation.map((edu) => EducationItemNameMap[edu as EducationItemType]).join(', ')}`
          : '';
      items.push({ type: 'EDUCATION', title: ProfileItemNameMap['EDUCATION'], description });
    }

    if (requiredItems.CAREER) {
      const filterCareer = requiredItems.CAREER.filter((career) => career !== 'CAREER');
      const description =
        filterCareer.length > 0
          ? `${filterCareer.map((career) => CareerItemNameMap[career as CareerItemType]).join(', ')}`
          : '';
      items.push({ type: 'CAREER', title: ProfileItemNameMap['CAREER'], description });
    }

    if (requiredItems.PROJECT) {
      items.push({ type: 'PROJECT', title: ProfileItemNameMap['PROJECT'], description: '' });
    }

    if (requiredItems.KNOWLEDGE_AND_SKILL) {
      const description =
        requiredItems.KNOWLEDGE_AND_SKILL.length > 0
          ? `${requiredItems.KNOWLEDGE_AND_SKILL.map(
              (item) => KnowledgeAndSkillItemNameMap[item as KnowledgeAndSkillItemType],
            ).join(', ')}`
          : '';
      items.push({ type: 'KNOWLEDGE_AND_SKILL', title: ProfileItemNameMap['KNOWLEDGE_AND_SKILL'], description });
    }

    if (requiredItems.EXPERIENCE) {
      const description =
        requiredItems.EXPERIENCE.length > 0
          ? `${requiredItems.EXPERIENCE.map(
              (experience) => ExperienceItemNameMap[experience as ExperienceItemType],
            ).join(', ')}`
          : '';
      items.push({ type: 'EXPERIENCE', title: ProfileItemNameMap['EXPERIENCE'], description });
    }

    if (requiredItems.PRIZE) {
      items.push({ type: 'PRIZE', title: ProfileItemNameMap['PRIZE'], description: '' });
    }

    if (requiredItems.PREFERENTIAL) {
      const description =
        requiredItems.PREFERENTIAL.length > 0
          ? `${requiredItems.PREFERENTIAL.map(
              (preferential) => PreferentialItemNameMap[preferential as PreferentialItemType],
            ).join(', ')}`
          : '';
      items.push({ type: 'PREFERENTIAL', title: ProfileItemNameMap['PREFERENTIAL'], description });
    }

    if (requiredItems.FILE) {
      const description =
        requiredItems.FILE.length > 0
          ? `${requiredItems.FILE.map((file) => FileItemNameMap[file as FileItemType]).join(', ')}`
          : '';
      items.push({ type: 'FILE', title: ProfileItemNameMap['FILE'], description });
    }

    this.requiredItems = items;
    this.isProfileComplete = items.length === 0;
  }
}
