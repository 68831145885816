import ProfileEducationVO from './ProfileEducationVO';
import ProfileCareersVO from './ProfileCareersVO';
import ProfileProjectsVO from './ProfileProjectVO';
import ProfileSkillVO from './ProfileSkillVO';
import ProfileExperienceVO from './ProfileExperienceVO';
import ProfilePrizeVO from './ProfilePrizeVO';
import ProfilePreferentialVO from './ProfilePreferentialVO';
import ProfileFileVO from './ProfileFileVO';
import { ChoiceType } from '@domain/rq/match/MatchingApplicationApplyRq';
import ProfileDetailRs, {
  AttachFileDto,
  BaseProfileBasicInfoDto,
  ProfileResumeAdditionDto,
  ProfileResumeAdditionInfoDto,
} from '@domain/rs/position/jd/ProfileDetailRs';
import { AdditionalItemType } from '@domain/constant/profileRequirementType';
import DateFormat from '@utils/date/format';
import TextFormat from '@utils/text/format';

export default class ProfileDetailVO {
  basicInfo: UserVO | null;
  educations: ProfileEducationVO | null;
  career: ProfileCareersVO | null;
  projects: ProfileProjectsVO | null;
  prize: ProfilePrizeVO | null;
  knowledgeAndSkill: ProfileSkillVO | null;
  experience: ProfileExperienceVO | null;
  preferential: ProfilePreferentialVO | null;
  file: ProfileFileVO | null;
  addition: AdditionVO | null;
  constructor(rs: ProfileDetailRs) {
    this.basicInfo = !rs.user ? null : new UserVO(rs.user);
    this.educations = !rs.education ? null : new ProfileEducationVO(rs.education);
    this.career = !rs.career ? null : new ProfileCareersVO({ profileCareers: rs.career.careers });
    this.projects = !rs.project ? null : new ProfileProjectsVO(rs.project);
    this.prize = !rs.prize ? null : new ProfilePrizeVO(rs.prize);
    this.knowledgeAndSkill = !rs.knowledgeAndSkill ? null : new ProfileSkillVO(rs.knowledgeAndSkill);
    this.experience = !rs.experience ? null : new ProfileExperienceVO(rs.experience);
    this.preferential = !rs.preferential ? null : new ProfilePreferentialVO(rs.preferential);
    this.file = !rs.file ? null : new ProfileFileVO(rs.file);
    this.addition = !rs.addition ? null : new AdditionVO(rs.addition);
  }
}

export class UserVO {
  profileImageUid: string | null;
  profileImageUrl: string;
  profileImageName: string | null;
  name: string | null;
  birthDate: string | null;
  birthDateText: string;
  mobile: string | null;
  mobileText: string;
  email: string | null;
  countryCode: string | null;

  constructor(rs: BaseProfileBasicInfoDto) {
    this.profileImageUid = rs.profileImage ? rs.profileImage.fileUid : null;
    this.profileImageUrl = rs.profileImage ? rs.profileImage.fileUrl ?? '' : '';
    this.profileImageName = rs.profileImage ? rs.profileImage.fileName : null;
    this.name = rs.name;
    this.birthDate = rs.birthDate;
    this.birthDateText = DateFormat.getDateFormatString(rs.birthDate, 'yyyy.MM.dd') ?? '';
    this.mobile = rs.mobile ?? '';
    this.mobileText = rs.mobile ? TextFormat.formatMobileWithHypen(rs.mobile) : '';
    this.email = rs.email;
    this.countryCode = rs.countryCode.toString();
  }
}

export class AdditionVO {
  additionInfo: AddtionDto[];

  constructor(rs: ProfileResumeAdditionDto) {
    this.additionInfo = (rs.additionInfo ?? []).map((dto) => new AddtionDto(dto));
  }
}

export class AddtionDto {
  question: string;
  answer: string;
  required: boolean;
  choices: ChoiceType[];
  files: AttachFileDto[];
  type: AdditionalItemType;
  maxAnswerSize?: number;

  constructor(dto: ProfileResumeAdditionInfoDto) {
    this.question = dto.question;
    this.answer = dto.answer;
    this.required = dto.required;
    this.choices = dto.choices;
    this.files = dto.files;
    this.type = dto.type;
    this.maxAnswerSize = dto.maxAnswerSize;
  }
}
