import ResumeAdditionInfoRs, {
  AnswerChoices,
  AnswerDto,
  AnswerFileDto,
  ResumeAdditionInfoDto,
} from '@domain/rs/match/ResumeAdditionInfoRs';
import { AttachFileDto } from '@domain/rs/position/jd/ProfileDetailRs';
import { AdditionalItemType } from '@domain/constant/profileRequirementType';

export type AttachFileDtoWithFileText = AttachFileDto & {
  fileNameText: string;
};

export default class ResumeAdditionInfoVO {
  additionInfo: AdditionInfoVO[];
  isAdditionalExist: boolean;

  constructor(rs: ResumeAdditionInfoRs) {
    this.additionInfo = (rs.additionInfo ?? []).map((dto) => new AdditionInfoVO(dto));
    this.isAdditionalExist = this.additionInfo.length > 0;
  }
}

export class AdditionInfoVO {
  additionInfoSn: number;
  question: string;
  required: boolean;
  choices: string[];
  files: AttachFileDtoWithFileText[];
  formType: AdditionalItemType;
  maxAnswerSize?: number | null;
  answer: AnswerVO | null;

  constructor(dto: ResumeAdditionInfoDto) {
    this.additionInfoSn = dto.additionInfoSn;
    this.question = dto.question;
    this.required = dto.required;
    this.choices = dto.choices ?? [];
    this.files =
      dto.files?.map((file) => {
        const splitedFileName = file.fileName.split('.');
        const fileName = splitedFileName[0];
        const fileExtention = splitedFileName[1];
        return {
          ...file,
          fileNameText: fileName.length > 9 ? `${fileName.slice(0, 9)}...${fileExtention}` : file.fileName,
        };
      }) ?? [];
    this.formType = dto.type;
    this.maxAnswerSize = dto.maxAnswerSize;
    this.answer = dto.answer ? new AnswerVO(dto.answer, dto.required, dto.additionInfoSn) : null;
  }
}

export class AnswerVO {
  additionInfoSn: number;
  question: string;
  type: AdditionalItemType;
  answer: string | null;
  choices: AnswerChoices[] | null;
  isValid: boolean;
  files: AnswerFileDto[];

  constructor(dto: AnswerDto, required: boolean, sn: number) {
    this.additionInfoSn = sn;
    this.question = dto.question;
    this.type = dto.type;
    this.answer = dto.answer;
    this.choices = dto.choices;
    this.isValid = this.getValid(dto, required);
    this.files = dto.files;
  }

  private getValid(dto: AnswerDto, required: boolean) {
    if (!required) {
      return true;
    } else {
      if (dto.type === 'TEXT') {
        return dto.answer !== null && dto.answer.length > 0;
      } else if (dto.type === 'SINGLE_CHOICE' || dto.type === 'MULTI_CHOICE') {
        return dto.choices?.some((choice) => choice.selectYn) ?? false;
      } else if (dto.type === 'DROPDOWN') {
        return dto.choices?.some((choice) => choice.selectYn) ?? false;
      } else if (dto.type === 'ADDITION_ATTACH_FILE') {
        return dto.files.length > 0;
      }
    }

    return false;
  }
}
